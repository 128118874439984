@media screen and (min-width: 1101px) and (max-width: 1300px) {
    .sistemas-balao {
        top: -170%;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1366px) {

    .header-img {
        background-position: 35% 0;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }


}