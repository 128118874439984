
@media screen and (min-width: 1367px) and (max-width: 1450px) {

    .header-img {
        /* background-position: 35% 0; */
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }


}