
@media screen and (min-width: 501px) and (max-width: 900px) {
    .flex-row {
        display: block;
    }

    .header-img {
        background-image: url("../img/capa-home-1024.jpg");
        background-position: 35% 0;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }
    
    .home-subtitulo {
        padding-left: 0%;
        padding-right: 0%;
    }

    .sistemas-card {
        margin-left: 5%;
        margin-right: 5%;
    }

    .youtube-iframe {
        padding-left: 15%;
        width: 380px;
        height: 220px;
    }

    .conheca-col {
        margin-top: 2%;
    }

    .conheca-block2 {
        margin: 0;
        padding: 0;
        margin-top: 10%;
        width: 100%;
        text-align: center;
    }

    .contatos-linha {
        padding-left: 1%;
        padding-right: 0%;
        text-align: left;
    }
    
    .contatos-right {
        width: 90%;
        text-align: left;
        margin-left: 20px;
        margin-top: 0px;
    }

    
    .contatos-left {
        margin-left: 5%;
        width: 70%;
    }

    .sistemas-bloco-titulo {
        margin-left: 0%;
        margin-bottom: 0%;
    }
    
    .beneficios-row {
        text-align: center;
        margin-left: 0%;
        margin-right: 0%;
        font-size: 17px;
    }

    .beneficios-icons {
        margin-top: 10%;
    }

    .beneficios-emissor {
        padding-bottom: 10%;
    }

    .recursos-row {
        text-align: center;
        margin-left: 0%;
        margin-right: 0%;
        padding-bottom: 0%;
        font-size: 17px;
        line-height: 1.0;
    }

    .recursos-emissor, .recursos-light, .recursos-manager, .recursos-gourmet {
        padding-bottom: 15%;
    }

    .conheca {
        padding-right: 0%;
        padding-left: 0%;
        text-align: center;
    }
    
    .conheca-col {
        margin-top: 5%;
        margin-left: 0px;
        text-align: center;
        
    }

    .home-col-form {
        margin-left: 26%;
        width: 50%;
    }

    .conheca-block2 {
        margin-top: 10%;
        width: 100%;
        margin-bottom: 10%;
        
    }

        
    .sistemas-subtitulo {
        margin: 0px;
        padding: 0px;
        padding-left: 0%;
        padding-right: 0%;
        color: #fff;
        font-weight: 600;
        font-size: 25px;
        font-family: Tahoma,Verdana,Segoe,sans-serif; 
        text-align: center;
    }

    .sistemas-subtitulo2 {
        margin: 0px;
        padding: 0px;
        padding-left: 0%;
        padding-right: 0%;
        color: #fff;
        font-weight: 600;
        font-size: 33px;
        font-family: Tahoma,Verdana,Segoe,sans-serif; 
        text-align: center;
    }

    
    .sistemas-card {
        background-color: #fff;
        border-radius: 10px;
        margin-left: 0%;
        margin-right: 0%;
        padding: 5px 3px 0px 3px;
    }

    .home-titulo {
        color: #961c23;
        font-weight: bold;
        font-size: 25px;
        font-family: Tahoma,Verdana,Segoe,sans-serif;
    }
    
    .home-subtitulo {
        margin-top: 5%;
        padding-left: 0%;
        padding-right: 0%;
        color: #434343;
        font-weight: 600;
        font-size: 21px;
        font-family: Tahoma,Verdana,Segoe,sans-serif; 
    }

    
    .nossa-historia {
        padding-left: 7%;
        padding-right: 7%;
        text-align: center;
    }

    .nossa-historia-img {
        margin-top: 0%;
        margin-bottom: 10%;
        height: 550px;
        text-align: center;
    }

    .nossa-historia-subtitulo {
        text-align: center;
        font-size: 20px;
        color: #8c8c8c;
        font-family: Tahoma,Verdana,Segoe,sans-serif;
    }

    
    .contato-redes-sociais {
        text-align: center;
    }

    .contato-sociais {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .contato-social-btn {
        width: 80px;
        padding-right: 10px;
        margin-bottom: 10px;
    }

    .contato-botoes {
        width: 90%;
        margin-left: 5%;
        font-weight: bold;
        font-size: 22px;
        font-family: Tahoma,Verdana,Segoe,sans-serif; 
        color: #818181;
    }

        
    .dialog-conteudo {
        padding: 15px 15px 15px 15px;
        background-color: #dadded;
    }

    .dialog-conteudo-trabalhe {
        padding: 15px 15px 15px 15px;
        background-color: #cee1da;
    }

    .dialog-conteudo-texto {
        line-height: 1.1;
        font-weight: 600;
        font-size: 10px;
        color: #818181;
    }

    .dialog-conteudo-texto-trabalhe {
        line-height: 1.3;
        font-weight: 600;
        font-size: 13px;
        color: #818181;
    }

    .contato-form-actions {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-around;
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        font-family: Tahoma,Verdana,Segoe,sans-serif; 
    }

    .card-body {
        padding: 10px 0px 10px 0px;
    }

    
    .comparativo-row {
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 5%;
    }

    /* .comparativo-sociais, .comparativo-conheca, .comparativo-contato, .comparativo-saiba-mais, .comparativo-saiba-mais2 {
        display: none;
    } */

    .comparativo-inner-texto {
        font-size: 12px;
        white-space: nowrap;
    }

    .comparativo-emissor-titulo, .comparativo-light-titulo, .comparativo-manager-titulo, .comparativo-gourmet-titulo {
        font-size: 9px;
    }

    
    .comparativo-col1 {
        margin: 3px;
        width: 42%;
        padding-top: 19px !important;
    }

    .comparativo-col2 {
        margin: 3px;
        width: 12%;
    }

    .comparativo-col3 {
        margin: 3px;
        width: 12%;
    }

    .comparativo-col4 {
        margin: 3px;
        width: 12%;
    }

    .comparativo-col5 {
        margin: 3px;
        width: 12%;
    }

    .comparativo-textos {
        padding-left: 5px;
        text-align: left;
        font-weight: 800;
        font-size: 14px;
        font-family: Tahoma,Verdana,Segoe,sans-serif; 
        color: #767676;
    }

    .header-img-emissor {
        background-position: 30% 0;
        text-align: center;
    }

    .emissor-header-titulo-1 {
        margin-top: 3%;
    }

    .emissor-header-titulo-2 {
        margin-top: 9%;
    }

    .emissor-header-titulo-3 {
        margin-top: 7%;
    }

    .header-img-gourmet {
        background-position: 30% 0;
        text-align: center;
    }

    .header-img-light {
        background-position: 30% 0;
        text-align: center;
    }

    .header-img-manager {
        background-position: 30% 0;
        text-align: center;
    }

    .comparativo-header-img {
        background-position: 30% 0;
        text-align: center;
    }

    .historia-header-img {
        background-position: 30% 0;
        text-align: center;
    }

    .contato-header-img {
        background-position: 50% 0;
        text-align: center;
    }

    .contato-titulo-mobile {
        color: #185d6c;
        font-weight: 600;
        font-size: 28px;
        font-family: Tahoma,Verdana,Segoe,sans-serif;
        text-align: center;
        padding-top: 5%;
    }

    .manager-header-titulo-2 {
        margin-top: 10%;
    }

    .manager-header-titulo-3 {
        margin-top: 20%;
    }

    .planos-header-titulo-1 {
        margin-top: 20%;
        padding: 4%;
    }

    .planos-header-titulo-2 {
        margin-top: 2%;
        padding: 3%;
    }

    .historia-header-titulo-1 {
        margin-top: 20%;
        padding: 4%;
    }

    .historia-header-titulo-2 {
        margin-top: 2%;
        padding: 3%;
    }

    .germantech-logo-footer {
        max-width: 180px;
    }

    .sistemas-balao {
        background-color: #fff;
        width: 50%;
        border-radius: 15px 15px 15px 0px;
        position: relative;
        top: 0%;
        right: 0%;
        opacity: 1;
        line-height: 1.2;
        box-shadow:  3px 3px 10px 0px #424242;
        margin-bottom: 2%;
        margin-top: 15%;
        margin-left: 25%;
    }

}