/* BODY */
.flex-row {
    display: flex;
    flex-direction: row;
}

body::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
  }
  body::-webkit-scrollbar-track {
    background: #bbb;        /* color of the tracking area */
  }
  body::-webkit-scrollbar-thumb {
    background-color: #961c23;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }

.wpp-fab {
    bottom: 0;
    right: 0;
    display: flex;
    position: fixed;
    z-index: 9999;
    width: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

/* NAVBAR */
.germantech-logo {
    margin-left: 5px;
    max-width: 160px;
}

/* HEADER */
.header-img {
    align-self: center;
    background-image: url("../img/header.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-img-light {
    align-self: center;
    background-image: url("../img/capa-light.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-img-emissor {
    align-self: center;
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-img-gourmet {
    align-self: center;
    background-image: url("../img/capa-gourmet.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-img-manager {
    align-self: center;
    background-image: url("../img/capa-manager.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-img-provendas {
    align-self: center;
    background-image: url("../img/capa-light.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* HOME */
.home-titulo {
    color: #961c23;
    font-weight: bold;
    font-size: 25px;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.home-subtitulo {
    margin-top: 5px;
    padding-left: 13%;
    padding-right: 13%;
    color: #434343;
    font-weight: 600;
    font-size: 21px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

.sistemas {
    align-self: center;
    background-image: url("../img/sistemas-background.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
}

.sistemas-titulo {
    margin: 0px;
    padding: 0px;
    /* margin-left: 13%; */
    /* margin-right: 13%; */
    color: #dfaf49;
    font-weight: 600;
    font-size: 40px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

.sistemas-subtitulo {
    margin: 0px;
    padding: 0px;
    padding-left: 0%;
    padding-right: 0%;
    color: #fff;
    font-weight: 600;
    font-size: 25px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

.sistemas-subtitulo2 {
    margin: 0px;
    padding: 0px;
    padding-left: 13%;
    padding-right: 13%;
    color: #fff;
    font-weight: 600;
    font-size: 33px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

.sistemas-card {
    background-color: #fff;
    border-radius: 10px;
    margin-left: 20%;
    margin-right: 20%;
    padding: 5px 3px 0px 3px;
}

.sistemas-card-text {
    text-align: center;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    font-weight: bold;
    padding: 4px;
    color: #666666;
}

.sistemas-slider-card {
    max-width: 400px;
    padding-top: 0px;
    background-color: rgba(201, 76, 76, 0.0)00;
    border-radius: 15px;
}

.sistemas-card-img:hover {
    /* border: #fff 1px solid; */
    box-shadow:
        0 0 3px 1px #fff,  
        0 0 3px 1px #434343, 
        0 0 3px 1px #fff; 
    cursor: pointer;
}

.sistemas-card-img {
    margin-top: 5px;
    border-radius: 20px;
}

.slider-home {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
}

.conheca {
    padding-right: 10%;
    padding-left: 10%;
}

.youtube-iframe {
    width: 400px;
    height: 220px;
}

.conheca-col {
    margin-left: 30px;
    text-align: center;
}

.conheca-titulo {
    font-size: 23px;
    color: #3b3b3b;
    font-weight: bold;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.conheca-subtitulo {
    font-size: 20px;
    color: #717171;
    text-align: justify;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.home-col-form {
    background-color: #e3e3e3;
    border-radius: 15px;
    text-align: center;
    padding-top: 3%;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 5%;
    box-shadow:  3px 3px 10px 0px #3b3b3b;
}

.home-span-form{
    color: #464646;
    font-size: 22px;
    font-weight: bold;
}

.home-text-div {
    margin-top: 4%;
    text-align: left;
    padding-left: 8%;
    padding-right: 8%;
    margin-bottom: 4%;
}

.home-text-form{
    color: #717171;
    font-size: 15px;
    font-weight: bold;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.home-text2-form{
    color: #717171;
    font-size: 20px;
    font-weight: bold;
}

.trabalhe-input {
    background-color: #fff !important;
    border: 0px;
    height: 40px;
    border-radius: 5px;
    color: #717171;
    font-weight: bold;
}

.dropdown-itens {
    font-size: 18px;
    font-weight: bold;
    color: #961c23;
}

.contato-input {
    background-color: #fff !important;
    border: 0px;
    height: 30px;
    border-radius: 15px;
    color: #717171;
    font-weight: bold;
}

.home-input {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
}


.btn-form-home {
    width: 90%;
    background-color: #fff;
    color: #717171;
    font-size: 15px;
    font-weight: 700;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.conheca-block2 {
    margin-top: 10%;
    width: 90%;
    text-align: center;
}

.conheca-titulo2 {
    font-size: 21px;
    color: #3b3b3b;
    font-weight: bold;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.conheca-subtitulo2 {
    font-size: 20px;
    color: #717171;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}


/* NOSSA HISTORIA */

.historia-header-img {
    align-self: center;
    background-image: url("../img/historia-header.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nossa-historia {
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
}

.nossa-historia-img {
    margin-top: 15%;
    height: 550px;
}

.nossa-historia-titulo {
    font-size: 23px;
    color: #585858;
    font-weight: bold;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.nossa-historia-subtitulo {
    text-align: justify;
    font-size: 22px;
    color: #8c8c8c;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

.historia-sistemas {
    align-self: center;
    background-image: url("../img/historia-background.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
}

.gourmet-sistemas {
    align-self: center;
    background-image: url("../img/gourmet-background.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
}

.manager-sistemas {
    align-self: center;
    background-image: url("../img/sistemas-background.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
}

.emissor-sistemas {
    align-self: center;
    background-image: url("../img/emissor-background.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
}

/* SISTEMAS */

.sistemas-carousel {
    padding-left: 0%;
    padding-right: 0%;
}

.react-multi-carousel-list {
    margin-left: 10%;
    margin-right: 10%;
    position: unset !important;
}


.sistemas-bloco-titulo {
    margin-left: 5%;
    padding-bottom: 2%;
}

.hr-sistemas {
    margin-left: 8%;
    margin-right: 8%;
    border-top: 3px solid #bbb;
}

.sistemas-pagina-titulo {
    padding-top: 35px;
    color: #961c23;
    font-weight: bold;
    font-size: 20px;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
    text-align: center;
}

.sistemas-pagina-subtitulo {
    margin-top: 5px;
    color: #868686;
    font-weight: 600;
    font-size: 17px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    text-align: center;
}

.sistemas-balao {
    background-color: #fff;
    width: 65%;
    border-radius: 15px 15px 15px 0px;
    position: absolute;
    top: -210%;
    right: 20%;
    opacity: 1;
    line-height: 1.2;
    box-shadow:  3px 3px 10px 0px #424242;
}

.sistemas-balao:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid #bbe3bb;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #bbe3bb;
    left: -25px;
    bottom: 0px;
  }

.sistemas-balao-titulo {
    padding-top: 25px;
    padding-bottom: 0px;
    color: #424242;
    font-weight: 800;
    font-size: 18px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    padding-left: 10%;
}

.sistemas-balao-subtitulo {
    padding-left: 27px;
    color: #424242;
    font-weight: 600;
    font-size: 15px;
    display: inline-block;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

.sistemas-balao-conteudo {
    padding-left: 25px;
    padding-right: 25px;
    color: #424242;
    font-weight: 500;
    font-size: 13px;
    font-family: Tahoma;
}

.sistemas-balao-conteudo2 {
    color: #424242;
    font-weight: 500;
    font-size: 13px;
    font-family: Tahoma;
}

.sistemas-balao-contato {
    background-color: #bbe3bb;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
    border-radius: 15px 15px 15px 0px;
    font-size: 15px;
}

.sistemas-balao-contato:hover {
    /* border: #fff 1px solid; */
    box-shadow:
        0 0 5px 2px #fff,  /* inner white */
        0 0 5px 2px #434343, /* middle magenta */
        0 0 5px 2px #0ff; /* outer cyan */
    cursor: pointer;
}

.sistemas-balao-wpp {
    width: 25px;
    margin-left: -25px;
    margin-right: 2px;
}

.sistemas-balao-contato-subtitulo {
    color: #434343;
    font-weight: 600;
    font-size: 15px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

/* EMISSOR */

.beneficios-emissor {
    background-color: #e2e2e2;
    padding-top: 50px;
    color: #3a3a3a;
}

.beneficios-titulo {
    width: 100%;
    text-align: center;
    color: #424242;
    font-weight: 800;
    font-size: 24px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

.beneficios-icons {
    width: 80px;
    margin-bottom: 15px;
}

.beneficios-row {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 5%;
    font-size: 17px;
}

.recursos-emissor {
    background: rgb(50,53,90);
    background: linear-gradient(180deg, rgba(50,53,90,1) 0%, rgba(80,74,117,1) 100%);
    padding-top: 50px;
    color: #fff;
}

.recursos-gourmet {
    background: rgb(206,133,58);
    background: linear-gradient(180deg, rgba(206,133,58,1) 0%, rgba(189,66,55,1) 100%);
    padding-top: 50px;
    color: #fff;
}

.recursos-light {
    background: rgb(119,180,176);
    background: linear-gradient(180deg, rgba(119,180,176,1) 0%, rgba(91,149,126,1) 100%);
    padding-top: 50px;
    color: #fff;
}

.recursos-manager {
    background: rgb(125,75,163);
    background: linear-gradient(180deg, rgba(125,75,163,1) 0%, rgba(37,30,76,1) 100%);
    padding-top: 50px;
    color: #fff;
}

.recursos-row {
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 5%;
    font-size: 17px;
    line-height: 1.0;
}

.recursos-top-titulo {
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: 800;
    font-size: 24px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    padding-bottom: 25px;
}

.recursos-titulo {
    font-weight: 800;
    font-size: 18px;
}

.recursos-subtitulo {
    font-size: 13px;
}

/* COMPARATIVO */

.comparativo-header-img {
    align-self: center;
    background-image: url("../img/capa-comparativo.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.comparativo-titulo {
    text-align: center;
    margin-top: 30px;
    font-weight: 800;
    font-size: 22px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #3e418e;
}

.comparativo-row {
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 5%;
}

.comparativo-col1 {
    margin: 10px;
    width: 30%;
}

.comparativo-col2 {
    margin: 10px;
    width: 14%;
}

.comparativo-col3 {
    margin: 10px;
    width: 15%;
}

.comparativo-col4 {
    margin: 10px;
    width: 15%;
}

.comparativo-col5 {
    margin: 10px;
    width: 15%;
}

.comparativo-rounded {
    border-radius: 10px;
    background-color: #fff;
    /* height: 300px; */
    box-shadow:  1px 2px 5px 0px #424242;
    text-align: center;
    padding-top: 10px;
}

.comparativo-sistema {
    width: 140px
}

.comparativo-contato {
    width: 140px;
}

.comparativo-emissor-titulo {
    font-weight: 800;
    font-size: 20px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #3c3fac;
}

.comparativo-light-titulo {
    font-weight: 800;
    font-size: 20px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #52adc7;
}

.comparativo-manager-titulo {
    font-weight: 800;
    font-size: 20px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #7546aa;
}

.comparativo-gourmet-titulo {
    font-weight: 800;
    font-size: 20px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #c07b3d;
}

.comparativo-check {
    width: 15px;
    padding-bottom: 5px;
}

.comparativo-divs {
    padding-top: 18px;
    padding-bottom: 10px;
}

.comparativo-div-checks {
    height: 27px;
}

.comparativo-conheca {
    width: 300px;
    margin-bottom: 15px;
}

.comparativo-sociais {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.comparativo-social-btn {
    width: 55px;
    padding-right: 15px;
}

.comparativo-textos {
    padding-left: 18px;
    text-align: left;
    font-weight: 800;
    font-size: 14px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #767676;
}

.comparativo-inner-texto {
    padding-bottom: 1px;
    height: 27px;
}

.hr-comparativo {
    margin-right: 1%;
    margin: 0px;
    padding: 0px;
    border-top: 1px solid #bbb;
}

.comparativo-saiba-mais2 {
    height: 70px;
}

.comparativo-saiba-mais {
    padding-top: 15px;
    height: 60px;
}

.comparativo-img-saiba-mais {
    width: 150px;
}

.pointer {
    cursor: pointer;
}

/* CONTATOS */


.inputfile {
    width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
  }

.anexo-input {
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    padding-top: 5px;
    padding-left: 10px;
    color: #818181;
}

.file-trabalhe-label {
    position: absolute;
    font-size: 11px;
    margin-top: -10px;
}
/*   
  .inputfile + .trabalhe-input {
    margin-top: 10px;
    border-radius: 4px;
    padding: 8px;
    font-size: 1.25em;
    color: white;
    background-color: #04445c;
    display: inline-block;
    cursor: pointer;
  }
  
  .inputfile:focus + .trabalhe-input,
  .inputfile + .trabalhe-input:hover {
    background-color: #002844;
  } */

.contato-header-img {
    align-self: center;
    background-image: url("../img/capa-contatos.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 10px;
}

.contato-titulo {
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #6c98b6;
}

.contato-subtitulo {
    font-weight: 800;
    font-size: 20px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #6c98b6;
    padding-left: 10px;
}

.contato-text-titulo {
    font-weight: 800;
    font-size: 18px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #6c98b6;
}

.contato-subtitulo2 {
    font-weight: 800;
    font-size: 14px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #6c98b6;
}

.contato-texto {
    font-weight: 800;
    font-size: 14px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #818181;
}

.contatos-linha {
    padding-left: 15%;
    padding-right: 15%;
    text-align: left;
}

.contatos-right {
    width: 55%;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
}

.contatos-left {
    width: 30%;
}

.hr-contatos {
    margin-right: 1%;
    margin: 0px;
    padding: 0px;
    border-top: 2px solid #bbb;
}

.contato-redes-sociais {
    text-align: center;
}

.contato-sociais {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.contato-social-btn {
    width: 120px;
    padding-right: 25px;
    padding-left: 25px;
}

.contato-botoes {
    width: 70%;
    font-weight: bold;
    font-size: 22px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
    color: #818181;
}

.btn-parceiro-contato {
    margin-top: 50px;
    width: 100%;
    background-color: #846cb3;
    padding: 30px;
}

.btn-parceiro-contato:hover, .btn-parceiro-contato:focus {
    background-color: #69578d;
}

.btn-contato-enviar:hover {
    color: #69578d;
}

.btn-trabalhe-contato {
    margin-top: 50px;
    width: 100%;
    background-color: #62a58b;
    padding: 30px;
    margin-bottom: 60px;
}

.btn-trabalhe-contato:hover, .btn-trabalhe-contato:focus {
    background-color: #4a816d;
}

.btn-contato-enviar-trabalhe:hover {
    color: #4a816d;
}

.dialog-conteudo {
    padding: 30px 30px 15px 30px;
    background-color: #dadded;
}

.dialog-conteudo-trabalhe {
    padding: 30px 30px 15px 30px;
    background-color: #cee1da;
}

.dialog-conteudo-texto {
    line-height: 1.1;
    font-weight: 600;
    font-size: 10px;
    color: #818181;
}

.dialog-conteudo-texto-trabalhe {
    line-height: 1.3;
    font-weight: 600;
    font-size: 13px;
    color: #818181;
}

.contato-form-actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    font-family: Tahoma,Verdana,Segoe,sans-serif; 
}

.btn-contato-fechar {
    color: #bbb;
}

.btn-contato-fechar:hover {
    color: rgb(134, 134, 134);
}

.btn-contato-enviar {
    color: #846cb3;
}

.btn-contato-enviar-trabalhe {
    color: #4aa89e;
}

/* FOOTER */

.germantech-logo-footer {
    max-width: 280px;
}

.footer {
    padding-left: 10%;
    padding-right: 10%;
    align-self: center;
    background-image: url("../img/footer-background.png");
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
}

.footer-contato-div {
    margin-left: 0px;
/* padding: 0px; */
}

.footer-title {
color: #fff;
font-size: 24px;
font-weight: bold;
margin-left: -20px;
}

.footer-subtitles {
padding-top: 7px;
font-weight: 100;
color: #fff;
font-size: 18px;
white-space: nowrap;
}

.footer-email {
padding-bottom: 10px;
font-weight: 100;
color: #fff;
font-size: 18px;
}

.footer-wpp {
padding-right: 5px;
cursor: pointer;
}

.footer-endereco {
padding-left: 0%;
white-space: normal;
}

.social-media {
padding-right: 10px;
font-size: 29px;
color: #fff;
}

.social-media:hover {
color: #bdbdbd;
}

.social {
margin-left: 30px;
}

.icon-contato {
padding-top: 0px;
padding-right: 8px;
font-size: 29px;
color: #fff;
}

.copyright {
padding-right: 10%;
}

.react-multiple-carousel__arrow:focus {
    outline: none;
    box-shadow: none;
}