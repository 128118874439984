
@media screen and (min-width: 901px) and (max-width: 1100px) {

    .home-subtitulo {
        padding-left: 0%;
        padding-right: 0%;
    }

    .sistemas-card {
        margin-left: 5%;
        margin-right: 5%;
    }

    .youtube-iframe {
        padding-left: 15%;
        width: 500px;
        height: 250px;
    }

    .conheca {
        padding-right: 3%;
        padding-left: 3%;
    }

    .conheca-col {
        margin-top: 2%;
    }

    .conheca-block2 {
        margin: 0;
        padding: 0;
        margin-top: 10%;
        width: 100%;
        text-align: center;
    }

    .germantech-logo-footer {
        max-width: 240px;
    }
    
    .sistemas-balao {
        background-color: #fff;
        width: 95%;
        border-radius: 15px 15px 15px 0px;
        position: relative;
        top: 0%;
        right: 0%;
        opacity: 1;
        line-height: 1.2;
        box-shadow:  3px 3px 10px 0px #424242;
        margin-bottom: 10%;
        margin-top: 15%;
        margin-left: 3%;
    }

    .sistemas-balao {
        background-color: #fff;
        width: 95%;
        border-radius: 15px 15px 15px 0px;
        position: relative;
        top: 0%;
        right: 0%;
        opacity: 1;
        line-height: 1.2;
        box-shadow:  3px 3px 10px 0px #424242;
        margin-bottom: 10%;
        margin-top: 15%;
        margin-left: 55%;
    }

    .comparativo-inner-texto {
        font-size: 12px;
        white-space: nowrap;
    }

    .contatos-linha {
        padding-left: 10%;
        padding-right: 10%;
        text-align: left;
    }

    .nossa-historia {
        padding-left: 5%;
        padding-right: 5%;
        text-align: center;
    }

    .titulo-sistemas {
        width: 90%;
        padding-left: 0%;
        flex-direction: column !important;
    }

    .titulo-sistemas-col {
        max-width: 100% !important; 
    }

    .titulo-sistemas-col2 {
        max-width: 50% !important; 
    }

}